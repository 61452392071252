import React, { useState } from "react";
import { Header } from "../wrappers/Header";
import logosData from "../data/sakinler.json";
import banner from "../assets/img/3.jpg";
import Footer from "../wrappers/Footer";
import { Modal } from "react-bootstrap";
import { Fade } from "react-reveal";

const Sakinler = () => {
  const sakinImages = logosData.map((resident, index) => ({
    id: index + 1,
    src: require(`../assets/img/logo/${resident.image}`),
    title: resident.title,
    website: resident.website,
    address: resident.address,
    mail: resident.mail,
    tel: resident.tel,
  }));

  const [selectedResident, setSelectedResident] = useState(null);

  const openModal = (resident) => {
    setSelectedResident(resident);
  };

  const closeModal = () => {
    setSelectedResident(null);
  };

  return (
    <>
      <Header />
      <div className="container">
        <div className="col-12 position-relative ">
          <img className="text-main-img radius-img" src={banner} alt="Banner" />
          <div className="text-main-center text-center text-white">
            <h1>Sakinler</h1>
          </div>
        </div>
        <h2 className="text-white text-center mt-5">
          "Koç Kuleleri'nde İş, Konfor ve Prestij Bir Arada!"
        </h2>
        <div className="mb-5 mt-5 d-flex flex-wrap justify-content-around">
          {sakinImages.map((resident) => (
            <div
              key={resident.id}
              className="col-lg-3  col-md-4 col-12 mb-4 card-sakin d-flex justify-content-center align-items-center flex-column text-white"
              onClick={() => openModal(resident)}
            >
              <div className="residents-card"><img
                className="sakin-style"
                src={resident.src}
                alt={`Sakin ${resident.id}`}
              />
              
              
            </div><p className="mt-1">{resident.title} </p></div>
          ))}
        </div>
      </div>
      <Fade bottom duration={5000}>
        {" "}
        <Modal
          show={selectedResident !== null}
          onHide={closeModal}
          // animation="slide-down"
          fullscreen
        >
          <Modal.Header closeButton />
          <Modal.Body className="text-white">
            <div className="col-8 d-lg-flex justify-content-center">
              <div className="col-lg-3 col-12">
                <img
                  className="img-fluid modal-img-style "
                  src={selectedResident?.src}
                  alt={`Sakin ${selectedResident?.id}`}
                />
              </div>
              <div className="col-lg-5 col-12 mt-lg-0 mt-5">
                <p className="p-0 m-0"> {selectedResident?.address}</p>
                <br />
                <p className="m-0 p-0"> {selectedResident?.website}</p>
                <br />
                <p className="m-0 p-0"> {selectedResident?.mail}</p>
                <br />
                <p className="m-0 p-0"> {selectedResident?.tel}</p>
                <br />
                <a href="/yapim-asamasinda" target="_blank">  <button className="button-type-two w-50">Yönetim Paneli</button></a>
              
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Fade>
     

      <Footer />
    </>
  );
};

export default Sakinler;
