import "./App.css";
import "./responsive.css"
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./components/Home";
import Duyurular from "./components/Duyurular";
import Sakinler from "./components/Sakinler";
import Iletisim from "./components/Iletisim";
import DuyurularSingle from "./components/DuyurularSingle";
import Yapim from "./components/Yapim";


function App() {
 
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Dashboard />} />
          <Route exact path="/duyurular" element={<Duyurular />} />
          <Route exact path="/duyurular/:name" element={<DuyurularSingle />} />
          <Route exact path="/sakinler" element={<Sakinler />} />
          <Route exact path="/iletisim" element={<Iletisim />} />
          <Route exact path="/yapim-asamasinda" element={<Yapim />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
