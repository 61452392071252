import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/img/logo.png";

export const Header = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isAnimationOpen, setIsAnimationOpen] = useState(false);

  const location = useLocation(); 
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggleMenu = () => {
    setMenuOpen(!isMenuOpen);
    setIsAnimationOpen(!isAnimationOpen);
  };

  return (
    <div className="d-flex text-white p-4 align-items-center w-100 header-main">
      <div className="col-6 d-flex align-items-center">
        <img src={logo} alt="Logo" />
        <a href="/">
          <h5>
            <b>KOÇ KULELERİ B BLOK</b>
          </h5>
        </a>
      </div>

      {screenWidth > 1024 ? (
        <div className="col-6 d-flex justify-content-around">
          <Link to="/" className={location.pathname === "/" ? "active-link" : ""}>
            <p>Anasayfa</p>
          </Link>
          <Link to="/duyurular" className={location.pathname === "/duyurular" ? "active-link" : ""}>
            <p>Duyurular</p>
          </Link>
          <Link to="/sakinler" className={location.pathname === "/sakinler" ? "active-link" : ""}>
            <p>Sakinler</p>
          </Link>
          <Link to="/iletisim" className={location.pathname === "/iletisim" ? "active-link" : ""}>
            <p>İletişim</p>
          </Link>
        </div>
      ) : (
        <div className="toggle">
          <input id="checkbox2" type="checkbox" />
          <label
            onClick={handleToggleMenu}
            className=" toggle2"
            htmlFor="checkbox2"
          >
            <div className="bars bar1"></div>
            <div className="bars bar2"></div>
            <div className="bars bar3"></div>
          </label>

          {isMenuOpen && (
            <div
              className={`active col-6 d-flex flex-column ${
                isAnimationOpen ? "active-open" : "active-close"
              }`}
            >
               <Link to="/" className={location.pathname === "/" ? "active-link" : ""}>
                Anasayfa
              </Link>
              <Link to="/duyurular" className={location.pathname === "/duyurular" ? "active-link" : ""}>
                Duyurular
              </Link>
              <Link to="/sakinler" className={location.pathname === "/sakinler" ? "active-link" : ""}>
                Sakinler
              </Link>
              <Link to="/iletisim" className={location.pathname === "/iletisim" ? "active-link" : ""}>
                İletişim
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
