import React from "react";
import footerLogo from "../assets/img/build2.svg";

const Footer = () => {
  return (
    <div className="footer-main d-flex align-item-center">
      <div className="container text-white align-items-center ">
        <div className="row">
          {" "}
          <div className="col-lg-4 col-md-3 col-12 d-flex justify-content-center flex-column">
            <h5>Hızlı Erişim</h5>
            <ul className="footer-text">
              <a href="/" target="_blank"><li>Anasayfa</li></a> 
              <a href="/duyurular" target="_blank"><li>Duyurular</li></a> 
              <a href="/sakinler" target="_blank"> <li>Sakinler</li></a>
              <a href="/iletisim"target="_blank"><li>İletişim</li></a> 
            </ul>
          </div>
          <div className="col-lg-4 col-md-5 col-12 footer-text d-flex justify-content-center flex-column">
            <h5>İletişim</h5>
            <div className="d-flex mt-1">
              <div className="col-3">
                <h5>Adres:</h5>{" "}
              </div>
              <div className="col-9">
                Söğütözü Mah. Söğütözü Cad.
                <br /> No:2/B Blok Çankaya/ANKARA
              </div>
            </div>
            <div className="d-flex mt-2">
              <div className="col-3">
                <h5>Lobi:</h5>
              </div>
              <div className="col-9">
                <a href="tel:+903125043661">+90 312 504 36 61</a>
              </div>
            </div>
            <div className="d-flex mt-2">
              <div className="col-3">
                <h5>Yönetim:</h5>
              </div>
              <div className="col-9">
                <a href="tel:+905425043661">+90 542 504 36 61</a>
              </div>
            </div>
            <div className="d-flex mt-2">
              <div className="col-3">
                <h5>Email:</h5>
              </div>
              <div className="col-9">
                <a href="mailto:kockuleleribblok@hotmail.com">
                  kockuleleribblok@hotmail.com
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-12 d-flex justify-content-end">
            <img src={footerLogo}></img>
          </div>
        </div>

        <div className="row ">
          {" "}
          <div className="col-8  ">
            <span>made by <a target="_blank" href="https://osicrew.com/">osiCrew</a></span>
          </div>
         
            <p>copyright © 2023 | <a target="_blank" href="https://osicrew.com/">osi</a> | all rights reserved.</p>
         
        </div>
      </div>
    </div>
  );
};

export default Footer;
