import React, { useState } from "react";
import { Header } from "../wrappers/Header";
import banner from "../assets/img/duyuru_banner.png";
import Footer from "../wrappers/Footer";

const Iletisim = () => {
  return (
    <>
      <Header />
      <div className="container">
        <div className="col-12 position-relative ">
          <img className="text-main-img" src={banner} alt="Banner" />
          <div className="text-main-center text-center text-white">
            <h1>İletisim</h1>
          </div>
        </div>
        <div className="bg-build-contact d-flex justify-content-center align-items-center">
          <div className="container">
            <div className="d-lg-flex mt-5">
              <div className="col-lg-5 col-12">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3060.3002780763227!2d32.804151499999996!3d39.9122959!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d348b3b125a689%3A0x9ca79f94c7adb83c!2zU8O2xJ_DvHTDtnrDvCwgU8O2xJ_DvHTDtnrDvCBDZC4gS2_DpyBLdWxlbGVyaSBObzoyLCAwNjUxMCDDh2Fua2F5YS9BbmthcmE!5e0!3m2!1str!2str!4v1703765056133!5m2!1str!2str"
                  width={"100%"}
                  height={450}
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
                <div className="d-flex align-items-center justify-content-center">
                 <a className="w-100" href="https://www.google.com/maps/search/S%C3%B6%C4%9F%C3%BCt%C3%B6z%C3%BC+Cd.+Ko%C3%A7+Kuleleri+No:2+S%C3%B6%C4%9F%C3%BCt%C3%B6z%C3%BC+06510+%C3%87ankaya%2FAnkara/@39.912296,32.804152,16z?hl=tr&entry=ttu" target="_blank"> <button className="button-type-two mt-lg-5 mt-3 mb-5 w-100">
                    Yol Tarifi Al
                  </button></a>
                 
                </div>
              </div>
              <div className="col-lg-6 col-12  offset-lg-1 text-white">
                <h2>
                  "İş Dünyasında Zirveye Adım Atın: <br />
                  Koç Kuleleri'nde İş Yapmanın Ayrıcalığı!"
                </h2>
                <div className="d-flex mt-5">
                  <div className="col-lg-2 col-3">
                    <h5>Adres:</h5>{" "}
                  </div>
                  <div className="col-lg-10 col-9">
                    Söğütözü Mah. Söğütözü Cad.
                    <br /> No:2/B Blok Çankaya/ANKARA
                  </div>
                </div>
                <div className="d-flex mt-2">
                  <div className="col-lg-2 col-3">
                    <h5>Lobi:</h5>
                  </div>
                  <div className="col-lg-10 col-9"><a href="tel:+903125043661">+90 312 504 36 61</a></div>
                </div>
                <div className="d-flex mt-2">
                  <div className="col-lg-2 col-3">
                    <h5>Yönetim:</h5>
                  </div>
                  <div className="col-lg-10 col-9"><a href="tel:+905425043661">+90 542 504 36 61</a></div>
                </div>
                <div className="d-flex mt-2">
                  <div className="col-lg-2 col-3">
                    <h5>Email:</h5>
                  </div>
                  <div className="col-lg-10 col-9"><a href="mailto:kockuleleribblok@hotmail.com">kockuleleribblok@hotmail.com</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>

      <Footer />
    </>
  );
};

export default Iletisim;
