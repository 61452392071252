import React, { useState, useEffect } from 'react';
import sol from "../assets/img/sol.png";
import sag from "../assets/img/sag.png";
import { Fade } from 'react-reveal';

const Loader = () => {

  return ( 
    <div>
      <div className="w-100 bg-color">
        <div className="col-12 tower-area d-flex justify-content-center align-items-center">
          <Fade bottom delay={200} duration={1200}>
            <div className="blokOne mb-5">
              <img src={sol} alt="blokOne" />
            </div>
          </Fade>

          <Fade bottom delay={400} duration={1200}>
            <div className="blokTwo">
              <img src={sag} alt="blokTwo" />
            </div>
          </Fade>
        </div>
      </div>
    </div>
  ) 
}

export default Loader;
