import React from "react";
import { useParams } from "react-router-dom";
import { Header } from "../wrappers/Header";
import Footer from "../wrappers/Footer";
import logosData from "../data/duyurular.json";
import duyuruBanner from "../assets/img/2.jpg"

const DuyurularSingle = () => {
  const { name } = useParams();
  const decodedTitle = decodeURIComponent(name.replace(/-/g, " "));
  const selectedAnnouncement = logosData.find(
    (resident) => resident.title === decodedTitle
  );
  const info = selectedAnnouncement?.info;
  const imageSrc = selectedAnnouncement?.image
    ? require(`../assets/img/${selectedAnnouncement.image}`)
    : "";
  

  return (
    <>
      <Header />
      <div className="container">
        {" "}
        <div className="col-12 position-relative ">
          <img className="text-main-img" src={duyuruBanner} alt="Banner" />
          <div className="text-main-center text-center text-white">
            <h1>{decodedTitle}</h1>
          </div>
        </div>{" "}
        <div className="text-white mt-5 mb-5">
          {" "}
          <h2>{decodedTitle}</h2> {info && <p className="mt-3">{info}</p>}
        </div>
      </div>
      <Footer />{" "}
    </>
  );
};

export default DuyurularSingle;
