import React, { useState } from "react";
import { Header } from "../wrappers/Header";
import logosData from "../data/duyurular.json";
import banner from "../assets/img/duyuru_banner.png";
import Footer from "../wrappers/Footer";
import png from "../assets/img/blog.png";

const Duyurular = () => {
  const [hoveredResident, setHoveredResident] = useState(null);

  const handleMouseEnter = (resident) => {
    setHoveredResident(resident.id);
  };

  const handleMouseLeave = () => {
    setHoveredResident(null);
  };

  const sakinImages = logosData.map((resident, index) => ({
    id: index + 1,
    src: require(`../assets/img/${resident.image}`),
    title: resident.title,
    info: resident.info,
    date: resident.date,
  }));

  return (
    <>
      <Header />
      <div className="container">
        <div className="col-12 position-relative ">
          <img className="text-main-img" src={banner} alt="Banner" />
          <div className="text-main-center text-center text-white">
            <h1>Duyurular</h1>
          </div>
        </div>

        <div className="mb-5 mt-5 d-flex flex-wrap justify-content-around">
          {sakinImages.map((resident) => (
            <div
              key={resident.id}
              className="col-lg-4 sakin-main col-md-6 p-md-1 position-relative col-12 mb-3 d-flex justify-content-center align-items-center"
              onMouseEnter={() => handleMouseEnter(resident)}
              onMouseLeave={handleMouseLeave}
            >
              <img className="text-main-img" src={resident.src} alt="" />
              <div
                className={`text-main-center-blog text-white ${
                  hoveredResident === resident.id ? "hovered" : ""
                }`}
              >
                 <a
                    target="_blank"
                    href={`/duyurular/${encodeURIComponent(
                      resident.title.replace(/\s/g, "-")
                    )}`}
                  >
                     <div className="content">
                  {" "}
                  <h4>{resident.title}</h4>
                  <p>{resident.date}</p>
                </div>
                  </a>
             

                {hoveredResident === resident.id && (
                  <a
                    target="_blank"
                    href={`/duyurular/${encodeURIComponent(
                      resident.title.replace(/\s/g, "-")
                    )}`}
                  >
                    <button className="button-type-two ">
                      {" "}
                      Daha Fazla
                    </button>
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Duyurular;
