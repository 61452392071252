import React from "react";
import logo from "../assets/img/build.png";
import gif from "../assets/img/yapim2.gif"
import { Fade } from 'react-reveal';

const Yapim = () => {
  return (
    <div
      className="d-lg-flex d-md-flex justify-content-center align-items-center text-white "
      style={{ height: "100vh" }}
    ><Fade duration={1500} top> <div className="col-lg-6 col-md-6 col-12">
        <img style={{maxHeight:"100vh"}} src={logo}></img>
      </div>{" "}</Fade>
     <Fade bottom>  <div className="col-lg-6 col-md-6 col-12 text-center">
        <img src={gif}></img>
        <h2 >
         Koç Kule B Blok Yönetim Paneli siz değerli site sakinleri için yapım
          aşamasındadır.
        </h2>
        <p className="mt-3">Söğütözü Mah. Söğütözü Cad. No:2/B Blok Çankaya/ANKARA</p>
      </div></Fade>
    
    </div>
  );
};

export default Yapim;
