import React, { useState, useEffect } from "react";
import Loader from "../wrappers/Loader";
import { Header } from "../wrappers/Header";
import video from "../assets/img/kule_web.mp4";
import img1 from "../assets/img/img1.png";
import img2 from "../assets/img/kule.jpg";
import img3 from "../assets/img/img3.png";
import img4 from "../assets/img/img4.png";
import img5 from "../assets/img/1.jpg";
import Marquee from "react-fast-marquee";
import Footer from "../wrappers/Footer";
import logosData from "../data/duyurular.json";
import png from "../assets/img/blog.png"


const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [hoveredResident, setHoveredResident] = useState(null);

  const handleMouseEnter = (resident) => {
    setHoveredResident(resident.id);
  };

  const handleMouseLeave = () => {
    setHoveredResident(null);
  };

  const latestDuyurular = logosData.slice(-3);

  const sakinImages = latestDuyurular.map((resident, index) => ({
    id: index + 1,
    src: require(`../assets/img/${resident.image}`),
    title: resident.title,
    info: resident.info,
    date: resident.date,
  }));

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 4200);

    return () => clearTimeout(timer);
  }, []);

  const handleClick = () => {
    window.scrollTo({
      top: window.scrollY + window.innerHeight * 1.3,
      behavior: 'smooth',
    });
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {" "}
          <Header />
          <div className="position-relative overflow-hidden">
            <video
              className="home-video"
              src={video}
              autoPlay muted loop 
            ></video>
            <div className="video-area">
              <h1>
                Ankara'nın İş Kalbinde Yükselen Çözümler: <br />
                Koç Kuleleri!
              </h1>
              <button className="button-type mt-5" onClick={handleClick}>
                KEŞFET
              </button>
            </div>
          </div>
          <div className="p-lg-5">
            {" "}
            <h1 className="text-center text-white mt-5 mb-5">
              KOÇ KULELERİ B BLOK
            </h1>
            <div className=" container  text-white ">
              <div className="d-lg-flex">
                {" "}
                <div className="col-lg-8 col-12  section-bg p-3 d-flex align-items-center flex-column justify-content-center">
                  <p>
                    Ankara’nın önemli ticaret merkezi olan Eskişehir yolu
                    aksında; şehrin sembolü olması hedeflenen bir ofis
                    projesidir. Bulunduğu konum itibari ile yapı hem Eskişehir
                    yolundan hem de Söğütözü Caddesinden cephe alarak;
                    Çukurambar aksını ve Eskişehir Yolu aksını birbirine
                    bağlamaktaydı. Şehrin bu gelişmekte olan iki önemli aksının
                    kesişiminde ortaya çıkan “bulutlara ulaşmak arzusu” bu proje
                    ile somutlaştırılmıştır. Bu somutlaştırmada ve yapının
                    mimari konseptinin oluşturulmasında şehrin durmaksızın
                    hareket halinde olan bu iki aksı büyük bir etkendir.
                  </p>
                  <p>
                    Yapının etrafında bulunan sürekli akışkanlık ve hız
                    kavramları; yapının biçimlenişinde bir tezatlık ile
                    karşılanarak, yapıya daha dingin ve net algılanabilir bir
                    form verilmiştir. Sonuçta yapı etrafında bulunan bu
                    karmaşadan sıyrılarak; nazik bir tavırla kendini var etmiş
                    ve altta bulunan bazasını bir sergi platformu gibi
                    kullanarak heykel vari bir görünüm kazandırılmıştır.
                  </p>
                </div>
                <div className="col-lg-4 col-12 d-flex justify-content-lg-end justify-content-center mt-lg-0 mt-5 align-items-center">
                  <img className="responsive-img-style" src={img1}></img>
                </div>
              </div>
              <Marquee className="marque-text mt-3 mb-3">
                YÜKSEK İŞ PERFORMANSI, KOÇ KULELERİ'NDE BAŞLAR !
              </Marquee>
              <div className="d-lg-flex">
                {" "}
                <div className="col-lg-4 col-12 mb-lg-0 mb-5 d-flex justify-content-lg-start justify-content-center align-items-center">
                  <img className="responsive-img-style radius-img" src={img2}></img>
                </div>
                <div className="col-lg-8  col-12 section-bg p-3 d-flex align-items-center flex-column justify-content-center">
                  <p>
                    Binanın mimari plastiğini oluşturmak ekibin üzerinde durduğu
                    önemli bir konuydu. Çünkü yapı; malzemesi ve duruşuyla bir
                    merkez nokta olmalıydı. Bu amaçları karşılayabilecek olan
                    yapı son derece narin, bir o kadar da gösterişli ve heybetli
                    olmalıydı. Bu fikirler ışığında yapı tasarlanırken hep iki
                    kule yapılması üzerinde duruldu. Bir bütünden ayrılmış iki
                    eş parça kabul gören fikir oldu.
                  </p>
                  <p>
                    Birbirini tamamlayan ve güçlendiren 2 kuleden oluşan ofis
                    bloğunun tasarımı bu şekilde gerçekleştirildi. Cephesi
                    30,000 m² panel cam sistemi ile çevrelenmiştir. Bu paneller
                    yapının dışa bakan ve birbirine bakan kısmında
                    farklılaştırıldı. İç köprüler ve iç yüzeyde daha şeffaf hale
                    getirilen yapının algıda bir bütünü hissettirmesi amaçlandı.
                  </p>
                </div>
              </div>{" "}
              <div className="col-12  section-bg p-3 d-flex align-items-center flex-column justify-content-center mt-4">
                <p>
                  Bunun yanında yapı değerli bir taş gibi güçlü ve etkin
                  olmalıydı. Çünkü bu bulunduğu konum ve sahip olduğu mimari
                  plastiğin bir gereğiydi. Bu yüzden yükselen kütleler minimum
                  çapta tutularak ve kısmen yere bastırılarak; alttaki
                  bazasından güç alan bir görsellik yaratıldı.
                </p>
                <p>
                  Yüksek blokların tasarımında takınılan yenilikçi üslup yapının
                  bütününe de yayıldı. Yapıya yaklaşımı kolaylaştırmak ve
                  kullanıcılara davetkar bir duruş sergilemek için yapı arsa
                  içerisinde geriye çekildi. Bu çekilme sonucu yapının önünde
                  çeşitli etkinliklerin yapılabileceği sosyal bir meydan
                  oluşturuldu. Bu kullanıcı bazlı sosyal tavır alt katlarda
                  bulunan alışveriş merkezine de taşınarak, kapalı alışveriş
                  merkezlerinden farklı bir alışveriş deneyimi sunan cadde
                  mağazalar kurgulandı.
                </p>
              </div>
              <div className="col-12 mt-4 position-relative d-flex justify-content-center">
                <img className="text-main-img" src={img3} />
                <div className="text-main-center text-center text-white">
                  <h1>KOÇ KULELERİ'NDE İŞ, KONFOR VE PRESTİJ BIR ARADA!</h1>
                </div>
              </div>
              <div className="d-lg-flex mt-4">
                {" "}
                <div className="col-lg-7 col-12 section-bg p-3 d-flex align-items-center flex-column justify-content-center">
                  <p>
                    Bu kurguyu desteklemek amacıyla yol kotundan rampa ile
                    ulaşılan ikinci bir zemin oluşturuldu. Bu zeminde
                    mağazaların yanı sıra geniş teraslara sahip kafeler
                    tasarlandı.
                  </p>
                  <p>
                    Yapı girişleri olabildiğince zarif bir tutum ile
                    tasarlandı.Zemin katta; ana yol ile arka sokak arasında
                    bağlantı kurmak amacıyla düzenlenen iç sokaktan ofis
                    kulelerine giriş alındı. Aynı zamanda yapılar arasında
                    kurgulanan köprüler ile iki kule arasında geçiş sağlandı. Bu
                    geçişlerde ofis çalışanlarının kullanması amacıyla kat
                    bahçeleri oluşturuldu. Yaklaşık 180 adet ofis ve çok sayıda
                    toplantı salonunun bulunduğu kulelerin çatısında katlar geri
                    çekilerek yeşil teraslar oluşturuldu. Cepheler için seçilen
                    malzemelerle enerji tasarrufu amaçlandı.
                  </p>
                </div>{" "}
                <div className="col-lg-5 col-12 d-flex justify-content-lg-end justify-content-center mt-lg-0 mt-5 d-flex align-items-center">
                  <img className="responsive-img-style" src={img4}></img>
                </div>
              </div>
              <div className="col-12  section-bg p-3 d-flex  flex-column justify-content-center mt-4">
                <p>
                  Yapıda tasarlanan kat bahçeleri, kullanıcıları doğa ile
                  buluştururken aynı zamanda çalışanların birbiriyle sosyal
                  iletişime girebilmesine imkan yaratmaktadır. Bina tasarımında
                  iklimden ve doğal koşullardan en üst düzeyde yararlanılmaya
                  çalışılmıştır.
                </p>
                <p>
                  Gerek dış gerekse iç mekanlarda, yaşam ve çalışma atmosferine
                  olumlu etki sağlayan malzeme, renk, hava gibi kriterler göz
                  önünde bulundurulmuştur.
                </p>
              </div>
            </div>
          </div>{" "}
          <div className="bg-build mb-5">
            <div className="container">
              <div className="text-white mt-4">
                <h1 className="mb-4">Duyurular</h1>
                <div className=" mt-5 d-flex flex-wrap justify-content-around">
                  {sakinImages.map((resident) => (
                    <div
                      key={resident.id}
                      className="col-lg-4 sakin-main col-md-6 p-md-1 position-relative col-12 mb-3 duyuru-main d-flex justify-content-center align-items-center"
                      onMouseEnter={() => handleMouseEnter(resident)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <img
                        className="text-main-img"
                        src={resident.src}
                        alt=""
                      />
                      <div
                        className={`text-main-center-blog text-white ${
                          hoveredResident === resident.id ? "hovered" : ""
                        }`}
                      >
                              <a
                          target="_blank"
                            href={`/duyurular/${encodeURIComponent(
                              resident.title.replace(/\s/g, "-")
                            )}`}
                          >
                            <div className="content">
                          {" "}
                          <h4>{resident.title}</h4>
                          <p>{resident.date}</p>
                        </div>
                          </a>
                       

                        {hoveredResident === resident.id && (
                          <a
                          target="_blank"
                            href={`/duyurular/${encodeURIComponent(
                              resident.title.replace(/\s/g, "-")
                            )}`}
                          >
                            <button className="button-type-two ">
                              {" "}
                              Daha Fazla
                            </button>
                          </a>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-12 d-flex justify-content-center">
                  <a target="_blank" href="/duyurular">
                    {" "}
                    <button className="button-type-two mt-3 w-100">
                      DAHA FAZLASINI GÖR
                    </button>
                  </a>
                </div>

                <div className="col-12 d-flex justify-content-center flex-column text-center align-items-center mt-5">
                  <h1 className="mt-lg-5 mt-3">
                    Koç Kuleleri B Blok Sakinleri
                  </h1>
                  <a href="/yapim-asamasinda" target="_blank">  <button className="button-type-two mt-5">
                    YÖNETİM PANELİ
                  </button></a>
                
                </div>
                <div className="col-12 mt-5 position-relative">
                  <img className="text-main-img radius-img" src={img5} />
                  <div className="text-main-center text-center text-white">
                    <h1>
                      Koç Kuleleri ile İletişime Geçin: İşiniz İçin En Doğru
                      Adres
                    </h1>
                <a href="/iletisim" target="_blank"><button className="button-type-two mt-lg-5 mt-0">
                    İletişim
                    </button></a>      
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default Home;
